import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layoutComponents/layout";
import { changeLocale } from "gatsby-plugin-intl";
import { useEffect } from "react";
import "./../styles/sections/portfolioitem.scss"


export default function Post({ data }) {
  const { html, frontmatter: post } = data.markdownRemark;

  useEffect(() => {
    if (post.lang !== "cs") {
      changeLocale(post.lang);
    }
  }, [post.lang]);

  return (
/*     <Layout title={post.title}>
      <article className="post-content">
        <h1>{post.title}</h1>
        <div className="post-text" dangerouslySetInnerHTML={{ __html: html }} />
      </article>
    </Layout> */
    <Layout title={post.title}>
    <section className='portfolioPost__hero' style={{
      backgroundColor: post.primary_color
    }
    }>
        <p className="hero__subject">{post.hero_subject}</p>
        <h1>{post.title}</h1>
        <h2>{post.hero_text}</h2>
        <a href={post.hero_link} target="_blank" rel="noreferrer">Visit website</a>
    </section>
    <section  className='portfolioPost__content' style={{
      backgroundColor: "black"
    }
    }>
    <div className="post-text" dangerouslySetInnerHTML={{ __html: html }} />
    </section>
  </Layout>
  );
}

export const pageQuery = graphql`
  query PostQuery($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date
        slug
        title
        lang
        primary_color
        hero_subject
        hero_text
        hero_link
      }
      timeToRead
    }
  }
`;
